<template>
  <ValidationProvider :name="title" :rules="rules" v-slot="{ errors }">
    <div class="input-group input-group-merge">
      <div class="input-group input-group-merge">
        <input
          type="text"
          class="form-control"
          :name="name"
          :placeholder="placeholder || 'Vui lòng nhập'"
          v-model.trim="content"
          :maxlength="maxlength"
          @input="onInput"
        />
      </div>
      <error-message :message="errors[0]"></error-message>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: 'Vui lòng nhập'
    },
    rules: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 255
    }
  },
  data() {
    return {
      content: null
    };
  },
  methods: {
    onInput() {
      this.$emit(`update:${this.model}`, this.content);
    }
  }
};
</script>