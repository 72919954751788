<template>
  <div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <div class="icon d-flex align-items-center justify-content-center" :class="bgClass">
        <i :class="iconClass"></i>
      </div>
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bgClass', 'iconUrl', 'iconClass', 'title']
};
</script>

<style lang="scss" scoped>
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    i {
      color: white;
      font-size: 24px;
    }
  }
</style>