import '../stylesheets/application'; // This file will contain your custom CSS
import Vue from 'vue';
import VeeValidate, { Validator, ValidationObserver, ValidationProvider } from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi';
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon';
import 'vue-datetime/dist/vue-datetime.css';
import vSelect from 'vue-select';
import Clipboard from 'v-clipboard';
import VTooltip from 'v-tooltip';
import VueLazyload from 'vue-lazyload';
// import 'bootstrap/js/dist/modal';
import Multiselect from 'vue-multiselect';
import 'vue-select/dist/vue-select.css';
import store from '../src/stores';
import '../src/filters';
import Rails from '@rails/ujs';

Rails.start();

window.$ = jQuery;
window._ = require('lodash');
const toastr = require('toastr');
window.toastr = toastr;
require('@rails/activestorage').start();

Vue.config.devtools = true;
Vue.use(Datetime);
Settings.defaultLocale = 'vi';
Vue.component('v-select', vSelect);
Vue.use(Clipboard);
Vue.use(VTooltip);
Vue.component('multiselect', Multiselect);
// Lazy loading image
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/images/no-image.png',
  loading: '/images/loading.gif',
  attempt: 1
});
// START: vee-validation configuration
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VeeValidate, { fieldsBagName: 'veeFields', locale: 'ja' });
Validator.localize('vi', vi);
Validator.extend('email', value => {
  var pattern = new RegExp('^\\w+([-+.\']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$');
  return !!pattern.test(value);
});
// END: vee-validation configuration

// Automatically import components
const files = require.context('../src', true, /\.vue$/i);
files.keys().map((key) => {
  const component = key
    .split('/')
    .pop()
    .split('.')[0];
  Vue.component(component, files(key).default);
});

// We have to re-create vue app when change the page url
jQuery(() => {
  new Vue({
    locale: 'ja',
    store
  }).$mount('#application');
});

toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  onclick: null,
  showDuration: '300',
  hideDuration: '500',
  timeOut: '2000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
};

// Unauthenticated detection
$(document).ajaxError((e, xhr, settings) => {
  if (xhr.status === 401) {
    location.reload();
  }
});
