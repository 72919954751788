
import EmployeeAPI from '../api/employee_api';

export const state = {
  employees: [],
  totalRows: 0,
  perPage: 0,
  queryParams: {
    page: 1,
    name_cont: null,
    status_eq: ''
  }
};

export const mutations = {
  setEmployees(state, employees) {
    state.employees = employees;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setQueryParam(state, param) {
    Object.assign(state.queryParams, param);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  async getEmployees(context) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const response = await EmployeeAPI.list(params);
      context.commit('setEmployees', response.data);
      context.commit('setMeta', response.meta);
      return response;
    } catch (error) {
      return error;
    }
  },

  createEmployee(_, query) {
    return EmployeeAPI.create(query).done((res) => {
      return Promise.resolve(res);
    }).fail((err) => {
      return Promise.reject(err);
    });
  },

  updateEmployee(_, query) {
    return EmployeeAPI.update(query).done((res) => {
      return Promise.resolve(res);
    }).fail((err) => {
      return Promise.reject(err);
    });
  }
};