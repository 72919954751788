<template>
  <div class="card shadow mb-3 p-1 bg-white rounded">
    <div class="card-body">
      <div class="d-flex align-items-center">
        <i :class="iconClass"></i>
        <span class="title ms-3">{{ title }}</span>
      </div>
      <div class="mt-2">
        <div class="progress">
          <div
            class="progress-bar bg-success"
            role="progressbar"
            style="width: 25%"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <div class="d-flex">
          <span class="sub-title">2/60 câu hỏi</span>
          <span class="sub-title ms-auto">đúng: 1, sai: 0</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['iconColor', 'iconClass', 'title']
};
</script>

<style scoped>
  .title {
    font-size: 1.2rem;
  }
  .sub-title {
    color: darkgray;
    font-size: 0.7rem;
  }
  i {
    font-size: 30px;
    text-align: center;
    width: 50px;
    color: #4b4646;
  }
  .progress {
    height: 0.5rem;
  }
</style>