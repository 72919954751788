<template>
  <div>
    <div class="card mt-2 shadow p-3 mb-4 bg-white rounded">
      <div class="card-body">
        <div class="row">
          <home-button
            iconUrl="/images/icons/ic_update.svg"
            bgClass="bg-green"
            iconClass="fa-solid fa-shuffle"
            title="Thi thử"
            class="col-4"
          ></home-button>
          <home-button
            iconUrl="/images/icons/ic_analytics.svg"
            bgClass="bg-primary"
            iconClass="fa-solid fa-book"
            title="Bộ đề"
            class="col-4"
          ></home-button>
          <home-button
            iconUrl="/images/icons/ic_traffic.svg"
            bgClass="bg-danger"
            iconClass="fa-solid fa-traffic-light"
            title="Biển báo"
            class="col-4"
            @click.native="openSignsIndex()"
          ></home-button>
        </div>
        <div class="row mt-4">
          <home-button
            iconUrl="/images/icons/ic_book.svg"
            bgClass="bg-warning"
            iconClass="fa-solid fa-lightbulb"
            title="Mẹo"
            class="col-4"
          ></home-button>
          <home-button
            iconUrl="/images/icons/ic_book.svg"
            bgClass="bg-info"
            iconClass="fa-solid fa-xmark"
            title="Câu bị sai"
            class="col-4"
          ></home-button>
          <home-button
            iconUrl="/images/icons/ic_analytics.svg"
            bgClass="bg-danger"
            iconClass="fa-solid fa-arrow-down-wide-short"
            title="Top câu sai"
            class="col-4"
          ></home-button>
        </div>
      </div>
    </div>

    <h4>Ôn tập theo chủ đề</h4>
    <div class="pb-4">
      <home-exercise-progress
        iconColor="#dc3545"
        iconClass="fa-solid fa-person-circle-question"
        title="Câu hỏi điểm liệt"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#4caf50"
        iconClass="fa-solid fa-scale-balanced"
        title="Khái niệm và quy tắc"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#ffc107"
        iconClass="fa-solid fa-bus"
        title="Nghiệp vụ vận tải"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#4caf50"
        iconClass="fa-solid fa-car-on"
        title="Văn hoá và đạo đức"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#0dcaf0"
        iconClass="fa-solid fa-taxi"
        title="Kỹ thuật lái xe"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#4caf50"
        iconClass="fa-solid fa-screwdriver-wrench"
        title="Cấu tạo và sửa chữa"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#dc3545"
        iconClass="fa-solid fa-traffic-light"
        title="Biển báo đường bộ"
      ></home-exercise-progress>
      <home-exercise-progress
        iconColor="#4caf50"
        iconClass="fa-solid fa-route"
        title="Sa hình"
      ></home-exercise-progress>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HomeExerciseProgress from './HomeExerciseProgress.vue';
export default {
  components: { HomeExerciseProgress },

  data() {
    return {
      license: 1
    };
  },

  beforeMount() {
    if (!this.licenseId) {
      window.location = '/setting';
    }
  },

  computed: {
    ...mapState('user', {
      licenseId: (state) => state.licenseId
    })
  },

  methods: {
    openSignsIndex() {
      window.location = '/signs';
    }
  }
};
</script>

<style lang="scss" scoped>
  .card-body {
    background-color: white;
  }
</style>