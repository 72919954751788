<template>
  <div>
    <h4>Loại bằng lái xe</h4>
    <ul class="list-group">
      <template v-for="license in licenses">
        <li
          class="list-group-item d-flex justify-content-between align-items-center"
          :key="license.id"
          @click="selectLicense(license.id)"
        >
          <div class="d-flex flex-row align-items-center">
            <div>
              <div class="text-info">{{ license.name }}</div>
              <div>{{ license.description }}</div>
            </div>
            <i class="fa-solid fa-check text-info" v-show="selectedLicenseId === license.id"></i>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    licenses: {
      type: Array
    }
  },
  data() {
    return {
      selectedLicenseId: parseInt(localStorage.getItem('licenseId')) || 1
    };
  },

  computed: {
    ...mapState('user', {
      licenseId: (state) => state.licenseId
    })
  },

  methods: {
    ...mapActions('user', ['setLicenseId']),

    selectLicense(id) {
      this.selectedLicenseId = id;
      localStorage.setItem('licenseId', id);
      this.setLicenseId(id);
    }
  }
};
</script>

<style>
</style>