
export const state = {
  licenseId: null
};

export const mutations = {
  setLicenseId(state, id) {
    state.licenseId = id;
  }
};

export const getters = {
};

export const actions = {
  setLicenseId({ commit }, id) {
    commit('setLicenseId', id)
  }
};