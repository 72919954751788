<template>
  <div class="input-group input-group-merge">
    <div class="input-group input-group-merge">
      <input type="text" class="form-control" v-model.trim="text" disabled="true" />
      <div class="input-group-append" @click="copyText()">
        <div class="input-group-text btn-copy">
          <span><i class="dripicons-copy"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['text'],
  methods: {
    copyText() {
      if (typeof (navigator.clipboard) === 'undefined') {
        this.copyUseCommand();
      } else {
        // not work in not secure context (example: http only), reference: https://stackoverflow.com/a/51823007
        navigator.clipboard.writeText(this.text);
        window.toastr.success('コピーしました');
      }
    },

    // use in case that site is not https
    copyUseCommand() {
      if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        var textarea = document.createElement('textarea');
        textarea.textContent = this.text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          const copySuccess = document.execCommand('copy');
          if (copySuccess) {
            window.toastr.success('コピーしました');
          } else {
            window.toastr.error('コピーは失敗しました');
          }
        } catch (exception) {
          window.toastr.error('コピーは失敗しました');
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .btn-copy {
    background-color: #fff;
    cursor: pointer;
  }
</style>