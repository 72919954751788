import { render, staticRenderFns } from "./HomeButton.vue?vue&type=template&id=34bf404e&scoped=true&"
import script from "./HomeButton.vue?vue&type=script&lang=js&"
export * from "./HomeButton.vue?vue&type=script&lang=js&"
import style0 from "./HomeButton.vue?vue&type=style&index=0&id=34bf404e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34bf404e",
  null
  
)

export default component.exports