<template>
  <div class="d-flex">
    <div v-bind:style="{ backgroundImage: `url(${imageUrl}` }" v-if="imageUrl" class="banner mr-2"></div>
    <div v-if="!imageUrl">
      <div class="btn btn-outline-primary mt-auto" data-toggle="modal" :data-target="`#imagemapModalUploadImage`">
        {{ buttonTitle || "画像をアップロード" }}
      </div>
    </div>
    <div class="d-flex mt-auto flex-column" v-else>
      <div class="btn btn-outline-danger" @click="setImageUrl(null)">削除</div>
      <div class="btn btn-outline-primary mt-1" data-toggle="modal" :data-target="`#imagemapModalUploadImage`">
        変更
      </div>
    </div>
    <!-- TODO -->

    <input class="fh-50" accept="image/*" :maxsize="10000" type="file" ref="file" @change="onSelectImage($event)" />
  </div>
</template>
<script>
export default {
  props: {
    buttonTitle: {
      type: String,
      required: false
    },
    inputName: {
      type: String,
      required: false
    }
  },

  methods: {
    onSelectImage(event) {
      console.log('------onSelectImage-----', event);
    },

    setImageUrl(url) {}
  }
};
</script>

<style scoped>
  .banner {
    width: 300px;
    height: 150px;
    background-size: cover;
  }
</style>
